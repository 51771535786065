/* Module: S */
.feature-blocks {
    
    width: 100%;
    @include sq-flex-box;
    flex-flow: row wrap;
    justify-content: center;
    @extend %clearfix;

    &--expand {
        margin-left: pxToRem(-20);
        margin-right: pxToRem(-20);
        width: auto;
    }

    &__inner {
        width: 100%;
    }

    &__green {

        @include sq-flex-box;
        flex-flow: row wrap;
        align-content: flex-start;
        align-items: $black;
        justify-content: $black;
        width: 100%;

        &-images {
            background: $mint-green;
            padding: pxToRem(20);
            position: relative;
            width: 100%;
            z-index: 150;
        }

        &-title {
            color: $white;
            font-weight: 300;
            @include font-smoothing;
            font-size: pxToRem(38);
            line-height: pxToRem(40);
            width: 100%;
        }

        &-image-large,
        &-image-medium,
        &-image-small,
        &-image-xsmall {
            position: absolute;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
            @include sq-border-radius(300px);
            display: none;
        }

        &-image-large {
            width: pxToRem(265);
            height: pxToRem(265);
            left: pxToRem(284);
            bottom: pxToRem(-85);
        }

        &-image-medium {
            width: pxToRem(160);
            height: pxToRem(160);
            left: pxToRem(50);
            top: pxToRem(242);
        }

        &-image-small {
            width: pxToRem(122);
            height: pxToRem(122);
            right: pxToRem(26);
            bottom: pxToRem(30);
        }

        &-image-xsmall {
            width: pxToRem(58);
            height: pxToRem(58);
            right: pxToRem(139);
            bottom: pxToRem(130);
        }
    }

    &__blue {
        
        @include sq-flex-box;
        flex-flow: row wrap;
        align-content: flex-start;
        align-items: $black;
        justify-content: $black;
        width: 100%;
        overflow: hidden;

        &-image-large,
        &-image-medium,
        &-image-small {
            position: absolute;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
            @include sq-border-radius(300px);
            display: none;
        }

        &-images {
            background: $navy;
            width: 65%;
            position: relative;
            overflow: hidden;
        }

        &-image-large {
            width: pxToRem(520);
            height: pxToRem(511);
            right: pxToRem(-135);
            top: pxToRem(-70);
            background-position: pxToRem(-10) pxToRem(15);
        }

        &-image-medium {
            width: pxToRem(245);
            height: pxToRem(245);
            left: pxToRem(45);
            top: pxToRem(47);
        }

        &-image-small {
            width: pxToRem(129);
            height: pxToRem(129);
            left: pxToRem(220);
            bottom: pxToRem(47);
        }

        .feature-blocks__content {
            padding-top: 0;
        }

        .feature-blocks__prospect {

            margin: pxToRem(36) 0 0 0;

            &-title {
                background: $navy;
                color: $white;
                @include font-smoothing;
            }
        }
    }


    &__title {
        color: black;
        font-weight: 400;
        font-size: pxToRem(30);
        line-height: pxToRem(35);
        position: relative;
        z-index: 50;
    }

    &__items {
        position: relative;
        z-index: 50;
        margin: pxToRem(20) 0 0 0;

        ul {
            @include sq-list-reset;

            li {
                @include sq-list-reset;
                font-size: pxToRem(20);
                line-height: pxToRem(27);
                margin: 0 0 pxToRem(8) 0;

                &:last-child {
                    margin-bottom: 0;
                }
                
                a {
                    color: $navy;
                    text-decoration: none;

                    &:hover,
                    &:focus {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &__content {
        background: $light-grey;
        padding: pxToRem(20) pxToRem(20) pxToRem(20) pxToRem(20);
        width: 100%;
        position: relative;
        height: auto;
    }

    &__prospect {
        width: 100%;
        margin: pxToRem(17) 0 0 0;
        @extend %clearfix;

        &-title {
            color: black;
            font-weight: 600;
            font-size: pxToRem(20);
            line-height: pxToRem(22);
            padding: pxToRem(9) pxToRem(15);
            background: $light-orange;
            width: auto;
            float: left;
            position: relative;
            z-index: 1;
        }

        &-link {
            width: 100%;
            float: left;
            position: relative;
            z-index: 100;

            a {
                display: block;
                float: left;
                background: black;
                color: $white;
                font-weight: 600;
                text-decoration: none;
                font-size: pxToRem(20);
                line-height: pxToRem(24);
                padding: pxToRem(10) pxToRem(14);
                @include font-smoothing;

                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }
    }

}