/* Module: S */
.feature-blocks {


    &--expand {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }
    
    &__green {
        flex-flow: row nowrap;

        &-title {
            color: $white;
            font-weight: 300;
            @include font-smoothing;
            // font-size: pxToRem(80);
            font-size: pxToRem(60);
            line-height: pxToRem(63.75);
            max-width: 80%;
        }

        &-images {
            
            // min-height: pxToRem(450);
            min-height: pxToRem(337.5);
            // padding: pxToRem(40) pxToRem(50) pxToRem(50) pxToRem(40);
            padding: pxToRem(30) pxToRem(37.5) pxToRem(37.5) pxToRem(30);
            max-width: pxToRem(735);

            &:before {
                content: "";
                right: pxToRem(-30);
                top: 35%;
                width: pxToRem(97.5);
                height: pxToRem(97.5);
                @include sq-border-radius(200px);
                position: absolute;
                background: $mint-green;
                z-index: 50;
            }

            &:after {
                content: "";
                left: pxToRem(127.5);
                bottom: pxToRem(-20.25);
                width: pxToRem(60);
                height: pxToRem(60);
                @include sq-border-radius(200px);
                position: absolute;
                background: $mint-green;
                z-index: 50;
            }
        }
        
        &-image-large,
        &-image-medium,
        &-image-small,
        &-image-xsmall {
            display: block;
        }

        &-image-large {
            width: pxToRem(198.75);
            height: pxToRem(198.75);
            left: pxToRem(213);
            bottom: pxToRem(-63.75);
        }

        &-image-medium {
            width: pxToRem(120);
            height: pxToRem(120);
            left: pxToRem(37.5);
            top: pxToRem(181.5);
        }


        &-image-small {
            width: pxToRem(91.5);
            height: pxToRem(91.5);
            right: pxToRem(19.5);
            bottom: pxToRem(22.5);
        }

        &-image-xsmall {
            width: pxToRem(43.5);
            height: pxToRem(43.5);
            right: pxToRem(104.25);
            bottom: pxToRem(97.5);
        }

        .feature-blocks__content {
            &:after {
                content: "";
                right: pxToRem(-192);
                top: 0;
                width: pxToRem(410);
                height: 100%;
                background: $light-grey;
                position: absolute;
                z-index: 0;
                @include sq-border-radius(0 900px 900px 0);
            }
        }

    }

    &__blue {
        flex-flow: row nowrap;

        .feature-blocks__content {
            padding: pxToRem(27.75) pxToRem(33.75) pxToRem(22.58) pxToRem(40);
            height: pxToRem(353);
        }
        
        &-image-large,
        &-image-medium,
        &-image-small {
            display: block;
        }

        &-image-large {
            width: pxToRem(414);
            height: pxToRem(383.25);
            right: pxToRem(-101.25);
            top: pxToRem(-52.5);
            background-position: pxToRem(-7.5) pxToRem(11.25);
        }

        &-image-medium {
            width: pxToRem(183.75);
            height: pxToRem(183.75);
            left: pxToRem(33.75);
            top: pxToRem(35.25);
        }

        &-image-small {
            width: pxToRem(96.75);
            height: pxToRem(96.75);
            left: pxToRem(165);
            bottom: pxToRem(35.25);
        }

        .feature-blocks__title {
            width: pxToRem(250);
        }

        .feature-blocks__prospect {
            margin-top: pxToRem(25);
        }

    }

    &__inner {
        // min-width: pxToRem(1240);
        min-width: pxToRem(768px);
    }

    &__title {
        font-size: pxToRem(30);
        line-height: pxToRem(33.75);
        width: pxToRem(337);
    }


    &__content {
        background: $light-grey;
        // padding: pxToRem(60) 0 pxToRem(30) pxToRem(70);
        padding: pxToRem(45) 0 pxToRem(22.5) pxToRem(52.5);
        width: 100%;
        max-width: pxToRem(417);
        position: relative;
        // height: pxToRem(452);
        height: pxToRem(339);

        .feature-blocks__items {
            li {
                line-height: pxToRem(20.25);
                margin-bottom: pxToRem(5);
                a {
                    font-size: pxToRem(15);
                }
            }
        }

        &--blue {
            width: pxToRem(340);
        }
    }

    &__items {
        margin-top: pxToRem(15);
    }

    &__prospect {
        margin-top: pxToRem(12);
    }

    &__prospect-title {
        font-size: pxToRem(15);
        padding: pxToRem(5) pxToRem(11.25) ;
    }

    &__prospect-link {
        a{
            font-size: pxToRem(15);
            padding: pxToRem(5) pxToRem(10.5);
        }       
    }
}