/* Module: S */
.header {

    position: relative;
    z-index: 500;

    &__logo {

        width: 100%;

        img {
            display: block;
            height: pxToRem(49);
            // float: right;
        }

        &-text-mobile {
            display: none;
        }
    }
    
    &__inner {
        margin: auto;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 0 pxToRem(20);
        align-content: center;
        align-items: center;
        max-width: pxToRem(1008);
    }

    &__top {
        // overflow: hidden;
    }

    &__toggle-btns {
        display: none;
    }

    &__left {
        max-width: pxToRem(622.5);
        padding: 0 pxToRem(30) 0 0;
        order: 1;
        background: none;
        @include sq-flex-box;
        flex-flow: row wrap;
    }

    &__right {
        order: 2;
        max-width: pxToRem(300);
    }

    &__search {
        display: block;
        position: relative;
        top: 0;
        padding: pxToRem(14) pxToRem(20) pxToRem(14) pxToRem(11.25);
        form {
            @include sq-border-radius(0);
            background: transparent;
            overflow: visible;
            justify-content: flex-start;
            padding: 0;

            .sq-form-question {
                order: 2;
                margin: 0;
                width: auto;
                margin-left: pxToRem(18);
            }

            .sq-form-control {
                order: 1;
                max-width: 100%;

                span {
                    display: block;
                }

                button {
                    padding: pxToRem(7.5) 0;
                    font-size: pxToRem(14);
                    font-weight: 500;

                    &:hover,
                    &:focus {
                        text-decoration: underline;
                    }

                    span {
                        margin-right: pxToRem(7.5);
                    }
                }

                svg {
                    color: white;
                    @include sq-transform(rotate(90deg));
                    width: pxToRem(18);
                    height: pxToRem(18);
                }
            }

            input[type="text"] {
                max-width: pxToRem(100);
                @include sq-transition(all 0.4s ease);
                @include sq-border-radius(0);
                padding: pxToRem(10.5) pxToRem(7.5);
                line-height: pxToRem(13.5);
                background-color: white !important;
                &.active {
                    max-width: 100%;
                }
            }
        }

    }

    &__details {
        justify-content: flex-start;
    }

    &__my-heti {
        width: 100%;
        max-width: pxToRem(113.25);
        justify-content: stretch;
        order: 1;

        a {
            padding: pxToRem(18.75) pxToRem(11.25);
            text-align: center;
            width: 100%;
            font-size: pxToRem(14);
            line-height: pxToRem(14.25);
            @include sq-flex-box;
            align-content: center;
            align-items: center;

            &:focus,
            &:hover {
                text-decoration: underline;
            }

            &[target="_blank"] {
                &:before {
                    content: "";
                    width: pxToRem(20);
                    height: pxToRem(20);
                    margin: 0 pxToRem(12) 0 0;
                }
            }
        }
    }

    &__contact {
        
        flex-flow: row nowrap;
        align-content: stretch;
        align-items: stretch;
        justify-content: stretch;
        order: 2;

        a {
            // padding: pxToRem(40) pxToRem(15) pxToRem(40) pxToRem(15);
            padding: pxToRem(30) pxToRem(20) pxToRem(30) pxToRem(11.25);
            font-size: pxToRem(14);
            line-height: pxToRem(19);
            color: black;
            background: $light-orange;
            
            position: relative;
            z-index: 50;
            font-weight: 500;
            
            &:after {
                content: "";
                width: 1100px;
                z-index: 0; 
                background: $light-orange;
                position: absolute;
                left: pxToRem(118);
                top: 0;
                height: 100%;
            }
        }
    }

    
}