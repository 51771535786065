/* Module: S */
.service-boxes {

    width: 100%;
    @include sq-flex-box;
    flex-flow: row wrap;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;

    &__inner {
        max-width: $page__max-width;
        width: 100%;
    }

    &__item {
        width: 100%;
        float: left;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        height: pxToRem(300);
        @include sq-flex-box;
        flex-flow: row wrap;
        justify-content: stretch;
        align-content: stretch;
        align-items: stretch;
        padding: pxToRem(25);
        margin: 0 0 pxToRem(20) 0;

        &--no-link {
            justify-content: center;
            align-content: center;
            align-items: center;
        }

        > a {
            @include sq-flex-box;
            flex-flow: row wrap;
            justify-content: center;
            align-content: center;
            align-items: center;
            width: 100%;
            font-size: pxToRem(15);

            &:hover,
            &:focus {
                .service-boxes__title {
                    text-decoration: underline;
                }
            }
        }
    }

    &__title {
        color: $black;
        font-weight: 500;
        font-size: pxToRem(20.25);
        line-height: pxToRem(29);
        text-decoration: none;
        text-align: center;
        width: 100%;
    }

    &__content {
        text-align: center;
        margin: pxToRem(15) 0 0 0;
        color: $black-five;
        font-size: pxToRem(15);
        line-height: pxToRem(25);
        padding: 0 pxToRem(50);
        p {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    ul {
        @include sq-list-reset;
        margin: 0 pxToRem(-50);

        li {
            @include sq-list-reset;
            text-align: center;
            padding: 0 0 pxToRem(12) 0;

            a {
                color: $navy;
                font-size: pxToRem(15);
                line-height: pxToRem(25);
                text-decoration: none;

                &:hover,
                &:focus {
                    
                    text-decoration: underline;
                }
            }

            svg {
                width: 100%;
                height: 100%;
                color: $black;
            }
        }
    }

    &__social {

        ul {

            margin: pxToRem(22) 0 0 0;
            float: left;
            display: block;
            width: 100%;

            li {

                float: left;
                display: block;
                width: auto;

                &:first-child {
                    a {
                        margin-left: 0;
                    }
                }

                a {
                    width: pxToRem(22);
                    height: pxToRem(22);
                    display: block;
                    float: left;
                    margin: 0 0 0 pxToRem(8);

                    &:hover svg {
                        color: $light-orange;
                    }
                }

                svg {
                    width: pxToRem(22);
                    height: pxToRem(22);
                    @include sq-transition(all 0.4s ease);
                }
            }
        }
    }

    a {
        text-decoration: none;
    }

}