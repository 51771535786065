// REM font sizing with pixel fall back
// This technique will allow external font sizes to be set for html and respected
// if REMs are supported (everything but IE8 and older).

/* stylelint-disable */
@mixin sq-font-size($sizeValue: 1) {
  @include sq-rem-attr(font-size, $sizeValue);
}

// Express the value of an attribute using rems falling back to pixels
@mixin sq-rem-attr($attr, $sizeValue: 1) {
  #{$attr}: ($sizeValue * $base__font-pixel) + px;
  #{$attr}: $sizeValue + rem;
}

// Transition shortcut
@mixin sq-transition($params...) {
  -webkit-transition: $params;
  -moz-transition: $params;
  transition: $params;
}

// Apply a prefixed transformation
@mixin sq-transform($params) {
  -webkit-transform: $params; // Chrome, Safari 3.1+
  -ms-transform: $params; // IE 9
  transform: $params;
}

// 2D rotation with IE support
@mixin sq-rotate($deg) {
  $msRotVal: (
    ((360 + $deg) % 360) / 90
  ); // Modulo lets us handle negative values.
  @include sq-transform(rotate($deg + deg));
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$msRotVal});
}

@mixin sq-border-box() {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin sq-border-radius($radius: 4px) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
          border-radius: $radius;

}

@mixin sq-reset-box-sizing() {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

// Glow effect taken from foundation
@mixin sq-block-glowing-effect(
  $selector: focus,
  $fade-time: 300ms,
  $glowing-effect-color: blue
) {
  -webkit-transition: -webkit-box-shadow $fade-time,
    border-color $fade-time ease-in-out;
  -moz-transition: -moz-box-shadow $fade-time,
    border-color $fade-time ease-in-out;
  transition: box-shadow $fade-time, border-color $fade-time ease-in-out;

  &:#{$selector} {
    @include sq-box-shadow(0 0 5px $glowing-effect-color);
    border-color: $glowing-effect-color;
  }
}

// Use an SVG background image (for perfect Retina-rendering) with a PNG fallback.
@mixin sq-svg-background($suffixless-url) {
  background-image: url($suffixless-url+".svg");
  .no-svg & {
    background-image: url($suffixless-url+".png");
  }
}

@mixin sq-flex-box() {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin sq-flex($values) {
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin sq-order($int: 0) {
  -webkit-order: $int;
  -ms-flex-order: $int;
  order: $int;
}

@mixin sq-flex-basis($value: auto) {
  -webkit-flex-basis: $value;
  -ms-flex-preferred-size: $value;
  flex-basis: $value;
}

@mixin sq-flex-shrink($int: 1) {
  -webkit-flex-shrink: $int;
  -ms-flex-negative: $int;
  flex-shrink: $int;
}

@mixin sq-flex-grow($int: 0) {
  -webkit-flex-grow: $int;
  -ms-flex-positive: $int;
  flex-grow: $int;
}

@mixin sq-justify-content($value: flex-start) {
  @if $value == flex-start {
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  justify-content: $value;
}

@mixin sq-flex-direction($value: row) {
  -webkit-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}

@mixin sq-align-items($value: stretch) {
  @if $value == flex-start {
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -ms-flex-align: end;
  } @else {
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  align-items: $value;
}

@mixin sq-flex-wrap($value: nowrap) {
  -webkit-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $value;
  }
  flex-wrap: $value;
}

@mixin sq-box-shadow($params) {
  -webkit-box-shadow: $params;
     -moz-box-shadow: $params;
          box-shadow: $params;
}

// Em calculator based on James' in Squiz Map issue #4906
@mixin sq-calc-ems($prop, $numerator, $denominator) {
  $tmp: ($numerator/$denominator);
  #{$prop}: #{$tmp}em;
  #{$prop}: calc(#{$numerator}em / #{$denominator});
}

// CSS arrow helper
// $direction: top, left, right, bottom
@mixin sq-arrow($direction: top, $color: #000, $size: 5px, $height: 0px) {
  $_height: $size + $height;

  height: 0;
  width: 0;
  content: " ";
  border-style: solid;

  @if $direction == "top" {
    border-width: $size $size $_height $size;
    border-color: transparent transparent $color transparent;
  } @else if $direction == "right" {
    border-width: $size $size $size $_height;
    border-color: transparent transparent transparent $color;
  } @else if $direction == "bottom" {
    border-width: $_height $size $size $size;
    border-color: $color transparent transparent transparent;
  } @else if $direction == "left" {
    border-width: $size $_height $size $size;
    border-color: transparent $color transparent transparent;
  }
}

@mixin sq-inline-block() {
  display: inline-block;
  *display: inline;
  zoom: 1;
}

@mixin sq-list-reset() {
  margin: 0;
  padding: 0;
  list-style: none;
}

// "Quantity queries" - a.k.a. "One of n siblings"
// From this codepen: http://codepen.io/long-lazuli/pen/PwBbmo
// http://alistapart.com/article/quantity-queries-for-css
@mixin sq-one-of-n-siblings($countArg...) {
  &:nth-last-child(#{$countArg}):first-child,
  &:nth-last-child(#{$countArg}):first-child ~ & {
    @content;
  }
}

@mixin sq-keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin sq-skew($deg) {
  -webkit-transform: skew($deg); // Safari
  -ms-transform: skew($deg); // IE 9
  transform: skew($deg);
}

// Font face
@mixin font-face(
  $name,
  $path,
  $weight: null,
  $style: null,
  $exts: eot woff2 woff ttf svg
) {
  $src: null;

  $extmods: (
    eot: "?",
    svg: "#" + str-replace($name, " ", "_")
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(
      map-has-key($extmods, $ext),
      $ext + map-get($extmods, $ext),
      $ext
    );
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append(
      $src,
      url(quote($path + "." + $extmod)) format(quote($format)),
      comma
    );
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

@mixin ordered-list($colour) {
  ol:not(.breadcrumbs__list) {
    counter-reset: li;
    @extend %list-reset;

    > li {
      position: relative;
      margin-bottom: 0.75rem;
      padding-left: pxToRem(26);

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        position: absolute;
        left: 0;
        content: counter(li) ". ";
        counter-increment: li;
        color: $colour;
        font-weight: bold;
      }

      ul,
      ol {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      ol {
        li::before {
          content: counter(li, lower-latin) ". ";
        }
      }
    }
  }
}

@mixin unordered-list($colour, $reverse: false) {
  ul:not(.no-content-styles, .pagination, .atcb-list) {
    @extend %list-reset;
    > li {
      position: relative;
      margin-bottom: 0;
      padding-left: pxToRem(13);
      line-height: pxToRem(22);

      @if $reverse == true {
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 9px;
          width: 9px;
          height: 2px;
          display: inline-block;
          background-color: $colour;
        }
      } @else {
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 9px;
          height: 5px;
          width: 5px;
          display: inline-block;
          background-color: $colour;
          border-radius: 50%;
        }
      }

      ul,
      ol {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      ul {
        li {
          @if $reverse == true {
            &::before {
              left: 0;
              top: 7px;
              height: 6px;
              width: 6px;
              border-radius: 50%;
            }
          } @else {
            &::before {
              content: "";
              position: absolute;
              top: 9px;
              width: 9px;
              height: 2px;
              border-radius: 0;
            }
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
/* stylelint-enable */

@mixin drop-shadow($opacity: 0.15, $offset: 1.5rem) {
  box-shadow: 0 $offset 50px -18px rgba(0, 0, 0, $opacity);
  will-change: transform;
}
@mixin ie-transparent() {
  background-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
}

//Font smoothing
@mixin font-smoothing() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin font-smoothing-reset() {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: initial;
}

//Placeholder color
@mixin placeholder-color($color) {
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $color;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: $color;
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    color: $color !important;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: $color;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    @content;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

@mixin clear-default-appearance {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: none;
  padding: 0;
}

@mixin dot-bg(
  $bg-color: $white-three,
  $dot-color: $black-five,
  $position: after
) {
  position: relative;
  @if $position == "after" {
    &:after {
      content: "";
      position: absolute;
      /* fill vertically */
      top: 0;
      bottom: 0;
      width: 9600px;
      left: 100%;

      // Dimensions
      $dot-size: 2px;
      $dot-space: 32px;

      background: linear-gradient(
            90deg,
            $bg-color ($dot-space - $dot-size),
            transparent 1%
          )
          center
          top,
        linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%)
          center
          top,
        $dot-color;
      background-size: $dot-space $dot-space;
    }
  } @else if $position == "before" {
    &:before {
      content: "";
      position: absolute;
      /* fill vertically */
      top: 0;
      bottom: 0;
      width: 9600px;
      right: 100%;

      // Dimensions
      $dot-size: 2px;
      $dot-space: 32px;

      background: linear-gradient(
            90deg,
            $bg-color ($dot-space - $dot-size),
            transparent 1%
          )
          center
          top,
        linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%)
          center
          top,
        $dot-color;
      background-size: $dot-space $dot-space;
    }
  }
}

