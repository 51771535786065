/* Module: S */
.feature-bubbles {

    width: 100%;
    margin: pxToRem(65) 0 0 0;

    &__title {
        font-size: pxToRem(30);
        line-height: pxToRem(35);
        color: $ocean-blue;
        font-weight: 400;
        text-align: center;
    }

    &__wrapper {
        margin: pxToRem(25) 0 0 0;
        width: 100%;
        @include sq-flex-box;
        flex-flow: row wrap;
        align-content: center;
        align-items: center;
        justify-content: space-between;
    }

    &__item {
        width: 100%;
        @include sq-flex-box;
        flex-flow: row wrap;
        justify-content: center;
        margin: 0 0 pxToRem(25) 0;

        &:last-child {
            margin-bottom: 0;
        }

        > a {

            display: block;
            max-width: pxToRem(200);

            &:hover,
            &:focus {
                .feature-bubbles__item-title {
                    text-decoration: none;
                }    
            }

            .feature-bubbles__item-title {
                color: $black;
                p {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
        }
    }

    &__img {
        
        @include sq-flex-box;
        flex-flow: row wrap;
        justify-content: center;
        align-content: center;
        align-items: center;

        img {
            width: 100%;
            max-width: 60%;
            @include sq-border-radius(999px);
        }
    }

    &__item-title {
        width: 100%;
        text-align: center;
        color: $black;
        font-size: pxToRem(16);
        line-height: pxToRem(19);
        text-decoration: underline;
        margin: pxToRem(15) 0 0 0;
        padding: 0 pxToRem(20);
    }

    &__links {
        width: 100%;
        margin: pxToRem(48) 0 pxToRem(35) 0;
        @include sq-flex-box;
        flex-flow: row wrap;
        justify-content: center;

        a {
            color: $white;
            background: $navy;
            @include sq-border-radius(9999px);
            padding: pxToRem(18) pxToRem(25) pxToRem(18) pxToRem(25);
            border: 3px solid $navy;
            text-decoration: none;
            font-size: pxToRem(26);
            line-height: pxToRem(28);
            @include font-smoothing;
            @include sq-transition(background-color 0.4s ease);

            &:hover,
            &:focus {
                background: $white;
                color: $navy;
            }

        }
    }

}