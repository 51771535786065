/* Module: S */
.landing-blocks {

    width: 100%;
    @include sq-flex-box;
    flex-flow: row wrap;
    justify-content: center;
    margin: pxToRem(20) 0 0 0;
    
    &__item {
        max-height: pxToRem(360);
        height: 88vw;
        max-width: pxToRem(360);
        width: 100%;
        @include sq-flex-box;
        justify-content: center;
        align-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-color: #eee;
        overflow: hidden;
        position: relative;
        transition: .2s ease-in background-color;
        &:hover {
            background-color: #ef670c;
        }

        a {
            display: block;
            width: 100%;
            text-align: center;
            text-decoration: none;
            position: relative;
            z-index: 50;

            &:hover,
            &:focus {

                .landing-blocks__img-general {
                    display: none;
                }

                .landing-blocks__img-hover {
                    display: block;
                }
            }
        }
    }
    
    &__title {
        font-size: pxToRem(30);
        line-height: pxToRem(33);
        color: $black;
        font-weight: 400;
        padding: 0 pxToRem(40);
        position: relative;
        z-index: 50;
    }

    &__img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;

        img {
            top: 50%;
            left: 50%;
            z-index: 1;
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            transform: translate(-50%, -50%);
            position: absolute;
        }

        .landing-blocks__img-hover {
            display: none;
        }
    }

}