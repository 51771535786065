/* Module: S */
.landscape-carousel {
  display: none;
  &__wrapper {
    position: relative;
  }

  &.slick-initialized {
    display: inherit;
  }
  &__item {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #116eb1; /* Old browsers */
      background: -webkit-linear-gradient(
        left,
        rgba(17, 110, 117, 1) 0%,
        rgba(17, 110, 117, 0.6) 50%,
        transparent 100%
      );
      background: -o-linear-gradient(
        left,
        #116eb1 0%,
        rgba(17, 110, 117, 0.6) 50%,
        transparent 100%
      );
      background: linear-gradient(
        to right,
        #116eb1 0%,
        rgba(17, 110, 117, 0.6) 50%,
        transparent 100%
      ); /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */ /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#116eb1', endColorstr='transparent',GradientType=1 ); /* IE6-9 */
    }
  }
}

.landscape-carousel,
.landscape-carousel__caption-wrapper {
  position: relative;
  overflow: hidden;
}

/* stylelint-disable */
.landscape-carousel__caption-wrapper {
  position: relative;
  padding-bottom: pxToRem(30);
  @include sq-flex-box;
  @include sq-justify-content(flex-start);
  width: 100%;
  max-width: $page__max-width;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  @include sq-transform(translate(-50%, -50%));
}
/* stylelint-enable */

.landscape-carousel__image {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: pxToRem(500);
  opacity: 0;
}

.landscape-carousel__caption {
  padding: pxToRem(28) 0;
  color: $white;
}

.landscape-carousel__caption-heading {
  font-size: pxToRem(30);
  font-weight: normal;
  font-family: $secondary__font-family;
  line-height: 1.39;
  margin: 0;
  padding: 0;
  color: $white;

  & ~ p {
    font-size: pxToRem(18);
  }
}

.landscape-carousel__caption-link {
  font-size: pxToRem(16);
  font-weight: bold;
  display: inline-block;
  padding: pxToRem(10) pxToRem(25);
  color: $white;
  background: $orange--font;
  border: 0 none;
  text-decoration: none;
  @include sq-transition(background);
  border-radius: pxToRem(20);
  margin-top: pxToRem(32);
  margin-bottom: pxToRem(10);

  &:hover {
    background: rgba($orange--font, 0.9);
    color: $white;
  }
}

.landscape-carousel__dots {
  @include sq-flex-box;
  @include sq-flex-direction(row);
  @extend %list-reset;
  > li {
    margin-right: 0.75rem;

    button {
      display: block;
      height: pxToRem(14);
      width: pxToRem(14);
      border-radius: 50%;
      background-color: transparent;
      border: pxToRem(2) solid #fff;
      margin: 4px 0;
      padding: 0;
      color: #757575;
      text-indent: 200%;
      white-space: nowrap;
      overflow: hidden;
    }

    &.slick-active {
      button {
        background-color: #fff;
      }
    }
  }
}

/* stylelint-disable */
.landscape-carousel__controls {
  @include sq-flex-box;
  @include sq-justify-content(flex-start);
  @include sq-align-items(center);
  width: 100%;
  max-width: $page__max-width;
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
  position: absolute;
  top: 10.5rem;
  left: 50%;
  @include sq-transform(translateX(-50%));
  top: auto;
  bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
/* stylelint-enable */

.landscape-carousel__toggler {
  display: block;
  background-color: transparent;
  height: pxToRem(14);
  width: pxToRem(12);
  border-width: 0 pxToRem(4) 0 pxToRem(4);
  border-style: none solid;
  border-color: transparent #fff;
  padding: 0;
  margin-left: 0.25rem;

  &.carousel-paused {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 0 7px 12px;
    border-color: transparent transparent transparent #fff;
  }
}
