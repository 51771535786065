/* Module: S */
.feature-note {

    padding: pxToRem(18.9) 0 pxToRem(39.75) 0;

    &--expand {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }

    &:before,
    &:after {
        display: block;
    }

    &:before {
        left: -29.28125rem;
        // width: pxToRem(337.5);
        background-size: 150%;
        background-position: -10.15625rem -4.6875rem;
    }

    &:after {
        right: pxToRem(-98.5);
        background-size: pxToRem(210);
    }

    &__inner {
        padding: 0;
        max-width: pxToRem(750);
        background-position: right pxToRem(-11.25);
    }

    &__title {
        font-size: pxToRem(18.75);
    }

    &__content {
        @include sq-flex-box;
        margin: pxToRem(23.25) 0 0 0;
        flex-flow: row nowrap;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: space-between;
    }

    &__left {
        margin: 0;
        max-height: pxToRem(96);
        overflow: hidden;
        font-size: pxToRem(15);
    }

    &__right {
        margin: 0 0 0 pxToRem(75);
        padding: 0 pxToRem(15) 0 0;
        max-height: pxToRem(72);
        overflow: hidden;
        font-size: pxToRem(15);
    }

}