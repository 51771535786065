/* Module: S */
.content-image {

    width: 100%;
    @extend %clearfix;
    margin: pxToRem(20) 0 pxToRem(8) 0;

    img {
        width: 100%;
        max-width: pxToRem(580);
        @include sq-border-radius(0 !important);
    }

    figcaption {
        display: none;
    }

}