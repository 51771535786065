.wrapper {
  padding-left: pxToRem(24);
  padding-right: pxToRem(24);
}

.main-content__wrapper,
.main__form form {
  @include sq-flex(1 1 auto);
  @include sq-flex-direction(row);
  @include sq-align-items(flex-start);
}

.wrapper {
  @include sq-flex-box;
  flex-flow: row wrap;
  justify-content: flex-start;
  max-width: $page__max-width;
  &--nowrap {
    flex-wrap: nowrap;
  }
}

.main-column__wrapper {
  width: 100%;

  &--sml {
    margin: 0 pxToRem(63.75) 0 0;
    max-width: pxToRem(465);
  }

  &--md {
    max-width: 75%;
  }


}

.main-content__wrapper,
.main__form form {
  @include sq-flex-wrap(wrap);
}
.main__content {
  width: auto;
  min-width: 1px;
  @include sq-flex(1 0 0%);
}
_::-webkit-full-page-media,
_:future,
:root .tool-box {
  @include sq-flex-basis(100%);
}
//
_::-webkit-full-page-media,
_:future,
:root .tool-box + .main__content {
  @include sq-flex-basis(1px);
}

.lhs__menu-toggle {
  display: none;
}

.lhs__menu-list:not(.menu__level-2, .menu__level-3, .menu__level-4, .menu__level-5) {
  display: inherit;
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

.tools-social {
  @include sq-flex-box;

  .landing-image__container + .wrapper & {
    margin-top: 1rem;
    position: absolute;
    right: pxToRem(20);
    bottom: pxToRem(-60);
  }
}

.tool-box {
  @include sq-align-items(flex-start);
  min-height: pxToRem(66);
  position: relative;

  .landing-image__container + .wrapper & {
    margin-top: pxToRem(-70);
    margin-bottom: pxToRem(30);
  }

  &.has__no-rhs:not(.has_search) {
    margin-bottom: pxToRem(92);
  }

}

.landing-image__container + .wrapper .tool-box--no-top {
  margin-top: 0;
}

.main__content {
  @include sq-flex(1 0 0%);
}

.responsive__table {
  table {
    min-width: auto;
    th {
      white-space: normal;
    }
  }
}

/*
--------------------
Modules
--------------------
*/

.section {

  width: 100%;
  @include sq-flex-box;
  flex-flow: row wrap;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
  
  &__inner {
    width: 100%;
    // max-width: $page__max-width;
    max-width: 1008px;
    padding: 0 pxToRem(20);
  }

  &__col {
    width: 100%;
    background: white;

    &--no-padd {
      padding: 0;
    }

  }

}

.button,
a.button {
  font-size: pxToRem(28.875);
  line-height: pxToRem(27);
  // padding: pxToRem(20) pxToRem(45) pxToRem(25) pxToRem(45);
  padding: pxToRem(15) pxToRem(33.75) pxToRem(18.75) pxToRem(33.75);
  margin: pxToRem(32.25) 0 pxToRem(38.25) 0;
}

a.cta-button {
  font-size: pxToRem(15);
  line-height: pxToRem(16.5);
  padding: pxToRem(7.5) pxToRem(15);
}


