/* Module: S */
.breadcrumbs {
    
    &__inner {
        max-width: $page__max-width;
        padding: pxToRem(15) pxToRem(15);
    }

    a { 
        font-size: pxToRem(13.5);
        line-height: pxToRem(16.5);
    }

    span {
        font-weight: 600;
        font-size: pxToRem(13.5);
        line-height: pxToRem(16.5);
    }

    li {
        
        padding-right: pxToRem(30);
        
        &:after {
            font-weight: 300;
            font-size: pxToRem(13.5);
            line-height: pxToRem(16.5);
            width: pxToRem(11.25);
            height: pxToRem(8.25);
            right: pxToRem(9);
		}

    }
}