/* Module: S */
.footer {
    
    background: $navy;
    border-top: pxToRem(20) solid $white;
    @include sq-flex-box;
    flex-flow: row wrap;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    padding: 0 0 pxToRem(55) 0;

    &--green {
        .footer__enews-title {
            background: $mint-green;

            &:before {
                background: $mint-green;
            }
        }

        .footer__scroll {
            button {
                background: $mint-green;
            }
        }
    }

    &__enews {
        width: 100%;
        @include sq-flex-box;
        flex-flow: row wrap;
        justify-content: center;
        align-content: flex-start;
        align-items: flex-start;
        width: 100%;
        background: $light-grey;
        padding: 0 pxToRem(20);

        .footer__inner {
            @include sq-flex-box;
            flex-flow: row wrap;
            align-items: stretch;
            align-content: stretch;
            justify-content: stretch;
        }

        &-title {
            width: 80%;
            font-size: pxToRem(24);
            line-height: pxToRem(27);
            color: $black;
            background: $light-orange;
            padding: pxToRem(15) pxToRem(50) pxToRem(20) 0;
            @include sq-border-radius(0 999px 999px 0);
            position: relative;
            max-width: 100%;
            z-index: 50;
            text-align: left;
            width: auto;
            float: left;
            margin: pxToRem(15) 0 0 0;

            &:before {
                content: "";
                left: pxToRem(-5999);
                top: 0;
                width: 6000px;
                height: 100%;
                position: absolute;
                background: $light-orange;
                z-index: 0;
            }
        }

        &-wrapper {
            width: 100%;
            @include sq-flex-box;
            flex-flow: row wrap;
            align-items: center;
            align-content: center;
            justify-content: stretch;
            position: relative;
            &--sending {
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    cursor: not-allowed;
                    background: #efefef;
                    opacity: 0.5;
                }
            }
        }

    }

    form {
        display: block;
        float: left;
        width: 100%;
    }

    input[type="text"],
    input[type="email"] {
        @include clear-default-appearance;
        width: 100%;
        padding: pxToRem(18) pxToRem(13);
        background: $white;
        color: $black;
        font-size: pxToRem(25);
        line-height: pxToRem(27);
        float: left;
        max-width: 100%;
        margin: pxToRem(15) 0 0 0;

        &::-ms-clear {
            display: none;
        }
        
        &::-webkit-input-placeholder {
            color: $black;
        }

        &::-moz-placeholder {
        color: $black;
        }

        &:-ms-input-placeholder {
            color: $black;
        }

        &:-moz-placeholder {
            color: $black;
        }
    }

    input[type="submit"] {
        @include clear-default-appearance;
        @include font-smoothing;
        display: block;
        float: left;
        background: $navy;
        font-size: pxToRem(20);
        line-height: pxToRem(20);
        padding: pxToRem(20) pxToRem(25);
        color: $white;
        font-weight: 600;
        width: 100%;
        margin: pxToRem(15) 0 pxToRem(20) 0;
        @include sq-transition(background-color 0.4s ease);

        &:focus,
        &:hover {
            background: $darker-navy;
        }
    }

    &__scroll {
        width: pxToRem(70);
        display: none;

        button {
            @include clear-default-appearance;
            @include sq-border-radius(999px);
            width: pxToRem(36);
            height: pxToRem(36);
            background: $light-orange;
            position: relative;
            @include sq-transition(all 0.4s ease);
            
            &:before,
            &:after {
                content: "";
                width: pxToRem(15);
                height: pxToRem(3);
                background: $navy;
                position: absolute;
                top: 0;
                left: 0;
                @include sq-border-radius(999px);
            }

            &:before {
                @include sq-transform(rotate(-45deg));
                left: pxToRem(6);
                top: pxToRem(15);
            }

            &:after {
                @include sq-transform(rotate(45deg));
                left: pxToRem(15);
                top: pxToRem(15);
            }

            &:hover,
            &:focus {
                @include sq-box-shadow(1px 2px 9px -1px black);
            }

        }
    }

    &__inner {
        width: 100%;
    }

    &__logo {
        
        padding-right: pxToRem(75);
        margin: 0 0 pxToRem(40) 0;

        a {
            display: block;
            width: 100%;
            max-width: pxToRem(240);
        }
        
        img {
             width: 100%;
        }
    }

    &__copyright {
        font-size: pxToRem(13);
        color: $white;
        margin-top: pxToRem(25);
    }

    &__lower {
        width: 100%;
        @include sq-flex-box;
        flex-flow: row wrap;
        justify-content: space-between;
        align-content: flex-start;
        align-items: flex-start;
        padding: pxToRem(55) pxToRem(20) 0 pxToRem(20);
    }

    &__right {
        @include sq-flex-box;
        flex-flow: row wrap;
        width: 100%;
    }

    &__col {
        width: 100%;
        padding: 0;
        margin: 0 0 pxToRem(30) 0;

        &-title {
            font-size: pxToRem(14);
            line-height: pxToRem(17);
            color: $light-orange;
            font-weight: 700;
            @include font-smoothing;
            text-transform: uppercase;

            a {
                color: $light-orange;
                text-decoration: none;
                margin:  0 0 pxToRem(16) 0;
                display: block;
                width: 100%;
                
                &:hover,
                &:focus {
                    color: $white;
                    border-color: $white;
                }
            }
        }

        ul {
            @include sq-list-reset;

            li {
                @include sq-list-reset;
                margin: 0 0 pxToRem(10) 0;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    color: $white;
                    text-decoration: none;
                    font-size: pxToRem(14);
                    line-height: pxToRem(17);
                    @include sq-transition(color 0.4s ease);
                    
                    &:hover,
                    &:focus {
                        color: $light-orange;
                    }
                }
            }
        }

        &--social {
            ul {
                
                @include sq-flex-box;
                flex-flow: row nowrap;
                align-content: center;
                align-items: center;
                justify-content: flex-start;

                li {

                    width: pxToRem(24);
                    height: pxToRem(24);
                    display: block;
                    margin: 0 0 0 pxToRem(10);

                    &:first-child {
                        margin-left: 0;
                    }

                    a {
                        width: 100%;

                        &:hover,
                        &:focus {
                            svg {
                                color: $light-orange;
                            }
                        }
                    }

                    svg { 
                        width: 100%;
                        height: 100%;
                        @include sq-transition(color 0.4s ease);
                    }
                }
            }
        }
    }


}