/* Module: S */
.service-boxes {

    &__inner {
        justify-content: space-between;
        @include sq-flex-box;
        flex-flow: row wrap;
    }

    &__item {
        width: 49%;
        margin: 0 0 pxToRem(20) 0;
    }

}