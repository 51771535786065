/* Module: S */
.banner-carousel {

    &__slide {
        height: pxToRem(652);
    }

    &__image {
        padding-top: 0;
        background-position: center center;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    &__content {
        display: block;
        font-size: pxToRem(15);
        margin-bottom: pxToRem(30);
    }

    &__content-wrapper {

        max-width: pxToRem(435);
        // padding: pxToRem(50) pxToRem(40) pxToRem(15) pxToRem(40);
        padding: pxToRem(37.5) pxToRem(30) pxToRem(11.25) pxToRem(30);
        // height: pxToRem(300);
        // height: pxToRem(225);

        &:after {
            content: "";
            left: pxToRem(-123.75);
            top: 0;
            position: absolute;
            height: 100%;
            width: pxToRem(292.5);
            background: $navy;
            @include sq-border-radius(300px);
        }
    }
    
    &__inner {
        margin: auto;
        // max-width: $page__max-width;
        max-width: 1008px;
        background: transparent;
    }

    &__title {
        // font-size: pxToRem(40);
        font-size: pxToRem(30);
        // line-height: pxToRem(42);
        line-height: pxToRem(31);
        margin: 0 0 pxToRem(25) 0;
        min-height: auto;
    }

    &__arrows {
        display: block;
        margin-right: pxToRem(55);

        .slick-prev:before,
        .slick-prev:after,
        .slick-next:before,
        .slick-next:after{
            height: pxToRem(2.25);
            width: pxToRem(21);
        }

        .slick-prev {
            &:before {
                top: pxToRem(24) !important;
                left: pxToRem(24.75) !important;
            }
            &:after {
                top: pxToRem(38) !important;
                left: pxToRem(24.75) !important;
            }
        }

        .slick-next {
            &:before {
                top: pxToRem(24) !important;
                left: pxToRem(24.75) !important;
            }
            &:after {
                top: pxToRem(38) !important;
                left: pxToRem(24.75) !important;
            }
        }

        .slick-arrow {
            height: pxToRem(67.5);
            width: pxToRem(67.5);          
        }
    }

    &__link {
        a {
            padding: pxToRem(9) pxToRem(7.5);
            font-size: pxToRem(15);
            line-height: pxToRem(18.75);
        }
    }

    &__controls {
        width: 100%;
        max-width: pxToRem(300);
        @include sq-flex-box;
        flex-flow: row wrap;
        justify-content: flex-end;
        align-content: center;
        align-items: center;
        float: right;
    }

    &__nav {
        position: absolute;
        left: 40vw;
        right: auto;
        bottom: pxToRem(45);
        width: 100%;
        max-width: pxToRem(740);
        @include sq-flex-box;
        flex-flow: row wrap;
        align-content: flex-end;
        align-items: flex-end;
        justify-content: flex-end;
    }

}