/* Module: S */
.page-banner {

    &__image {
        height: pxToRem(500);
    }

    &__content {
        padding: 0 pxToRem(90) pxToRem(30) pxToRem(20);
    }

}