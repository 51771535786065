/* Module: S */
.landing-blocks {

    margin: 0 0 pxToRem(56.25) 0;

    &__item {
        width: 33.33%;
        height: pxToRem(296.25);
        max-width: 100%;
        border: 1px solid $white;
    }

    &__title {
        font-size: pxToRem(30);
        line-height: pxToRem(32.25);
        padding: 0 pxToRem(85);
    }
    
}