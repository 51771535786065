/* Module: S */
.lhs__menu {
    margin-top: 0;
    width: 100%;
    max-width: pxToRem(165);
    margin: 0 pxToRem(60) 0 0;
    flex-shrink: 0;

    &-toggle {
        font-size: pxToRem(12);
        padding: pxToRem(9.75) pxToRem(15);

        &::after {
            top: pxToRem(11.25);
            right: pxToRem(12);
            width: pxToRem(9);
            height: pxToRem(9);
        }

        &[data-expanded="true"] {

          &::after {
            top: pxToRem(15.75);
          }
        }
    }

    &-list:not(.menu__level-2, .menu__level-3, .menu__level-4, .menu__level-5) { 
        margin-bottom: 1.5rem;
        border-radius: 0 0 pxToRem(7.5) pxToRem(7.5);
    }

    &-list {

        &.menu__level-2 {
            padding: 0 pxToRem(15);

            li {
                a {
                    padding: pxToRem(6) 0 pxToRem(6) pxToRem(22.5);
                }
            }
        }

        &.menu__level-3,
        &.menu__level-4 {
          padding: 0 pxToRem(7.5);
        }
    }

    &-item {
        &:last-child {
          padding-bottom: pxToRem(7.5);
        }
    }

    .lhs__menu &-link {
        padding: pxToRem(15);
        font-size: pxToRem(13.5);
        padding: pxToRem(10.5) pxToRem(15) pxToRem(5.25) pxToRem(15);

        &.current.has-children {
            &::after {
                top: pxToRem(16.5);
                right: pxToRem(21);
            }
        }

        .menu__level-2 & {
            padding: pxToRem(7.5) 0 pxToRem(7.5) pxToRem(15);
        }

        .menu__level-3 & {
            font-size: pxToRem(12);
        }

          .menu__level-4 & {
            font-size: pxToRem(11.25);
        }
    }
    
}

.featured-search + .wrapper {
    .lhs__menu {
        margin-top: 0;
    }
}

.rhs__column {
    display: block;
    @include sq-flex-basis(pxToRem(208.5));
    margin-top: pxToRem(0);
    margin-left: $rhs-column__margin-left;

    .is-listing + & {
        @include sq-flex-shrink(0);
        @include sq-order(inherit);
    }

    &--no-marg {
        margin: 0;
    }
}

.rhs__panel {
    width: 100%;
    margin-bottom: pxToRem(22.5);
    max-width: pxToRem(205);
}

.rhs-panel__link {
    max-width: $rhs-column__width + 1.5rem;
}


.rhs__panel {
    .rhs__panel-heading {
        font-size: pxToRem(18.75);
        margin: 0 0 pxToRem(11.25) 0;
    }

    .rhs-panel__link { 
        font-size: pxToRem(13.5);
        line-height: pxToRem(15);
        font-weight: 400;
        margin: 0 0 pxToRem(5) 0;
    }
}


.form-fieldset__content {
    display: inherit;
    margin-top: 0;
}

.addtocalendar {
    .atcb-list {
        left: auto;
        right: 0;
    }
}
.btt__wrapper {
    padding: 0 1.5rem;
}


.toc__box {
    display: block;
}


.breadcrumbs__link,
.breadcrumbs__current {
    color: $white;
    position: relative;
    @include font-smoothing();
}

.breadcrumbs__link {
    padding-right: pxToRem(15);

    &:hover {
        color: $white;
    }

    &:after {
        border-color: $white;
        margin-left: pxToRem(15);
    }
}

$bigger-rhs__width: $rhs-column__width + 2rem;
$bigger-lhs__width: $lhs-menu__width + 2rem;

.map__description {
    width: 40%;
    position: absolute;
    bottom: pxToRem(-37.5);
    left: pxToRem(12);
}
.map__wrapper{
    position: relative;
    margin-bottom: calc(2.25rem + 37.5px);

}
