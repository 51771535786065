/* Module: S */
.page-banner {

    height: pxToRem(650.25);
    margin: 0 0 pxToRem(120) 0;

    &__image {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        height: 100%;
    }

    &__inner {
        max-width: $page__max-width;
        padding: 0 pxToRem(15);
    }

    &__lower {
        position: absolute;
        left: 0;
        bottom: pxToRem(-120)!important ;
        width: 100%;
        padding: 0 pxToRem(15);
    }

    &__header {
        max-width: pxToRem(410);
        padding: pxToRem(30.75) pxToRem(71.25) pxToRem(30.75) 0;
        &:before {
            right: pxToRem(407);
            width: pxToRem(258);
        }
    }

    &__title {
        font-size: pxToRem(60);
        line-height: pxToRem(60);
        font-weight: 300;
    }

    &__content {
        color: $navy;
        display: block;
        font-size: pxToRem(18.75);
        line-height: pxToRem(22.5);
        position: absolute;
        left: pxToRem(455.75);
        bottom: pxToRem(24);
        max-width: pxToRem(472.5);
        max-height: pxToRem(67.5);
        background: transparent !important;
        padding: 0;
    }

    &__links {
        display: block;
        margin: pxToRem(11.25) 0 0 pxToRem(6);
        a {
            font-size: pxToRem(15);
            padding: pxToRem(10.5);
        }
    }

    &--short {
        height: pxToRem(375);
        margin: 0 0 pxToRem(48.75) 0;
        // bottom: pxToRem(-48.75)!important ;
        .page-banner__lower {
            bottom: pxToRem(-48.75)!important ;
        }
        .page-banner__content {
            font-size: pxToRem(22.5);
            line-height: pxToRem(26.25);
            bottom: pxToRem(11.25);
            left: pxToRem(463.25);
            margin: 0;
        }
    }

}