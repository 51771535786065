/* Module: S */
body[data-nav-position="top"] {
    .navigation {
        display: block;
        position: static;
        left: auto;
        background: $navy;
        width: 100%;
    }
}

.navigation {
    background: $navy;
    position: static;
    top: auto;
    left: auto;
    bottom: auto;
    z-index: 99;
    @include sq-transition((none)); 
    overflow: visible;
    visibility: visible;
    width: 100%;
    @include font-smoothing-reset;
    @include sq-box-shadow(none);
    z-index: 500;

    &.active {
        left: auto;
        visibility: visible;
    }

    &__wrapper {
        position: static;
        top: auto;
        left: auto;
        width: 100%;
        min-height: 0;
        @include sq-transition((none)); 
        padding: 0;
        margin: auto;
        // max-width: $page__max-width;
        max-width: 1008px;
	}

	&__toggle {
	    display: none;
	}

    &__info {
        display: block;
        float: left;
        width: 25%;
        padding-right: pxToRem(60);
    }

    &__col-outer {
        width: 75%;
        float: left;
        @include sq-flex-box;
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    &__title {
        display: block;
        font-size: pxToRem(36);
        line-height: pxToRem(40);
        color: $pumpkin;
        padding: 0 pxToRem(40) 0 0;
    }

    &__col {
        padding: 0 pxToRem(20) 0 0;
        width: 100%;
    }

    &__level-1 {
        @include sq-transition(opacity 0.3s, visibility 0.3s); 
        opacity: 1;
        visibility: visible;
        @include sq-flex-box;
        flex-flow: row wrap;
        align-content: center;
        justify-content: flex-start;

        >li {
            border-bottom: none;
            float: left;
            margin-right: pxToRem(112.5);


            &:last-child {
                margin-right: 0;
            }

            &:hover {
                .navigation__level-2-wrapper {
                    display: block;
                }
            }

            &.open {
                position: static;
                
                .navigation__level-2-wrapper {
                    display: block;
                }

                .navigation__level-1-link > a {
                    background-image: url('../mysource_files/nav-circle.gif');
                }
            }

            &.active {
                .navigation__level-1-link > a {
                    background-image: url('../mysource_files/nav-circle.gif');
                }
            }

        }

        &-link {
            &.parent {
                a {
                    padding-right: pxToRem(5);
                }
            }

            a {
                color: white;
                padding: pxToRem(11.25) pxToRem(15) pxToRem(11.25) pxToRem(15);
                position: relative;
                font-size: pxToRem(15);
                line-height: pxToRem(24);
                @include font-smoothing;
                font-family: $secondary__font-family;
                font-weight: 600;
                text-align: center;
                display: block;
                width: 100%;
                background-position: bottom center;
                background-repeat: no-repeat;
                background-size: pxToRem(25);

                &:after {
                    display: none;
                }

                &:hover,
                &:focus {
                    background-image: url('../mysource_files/nav-circle.gif');
                }
            }
        }
    }

    &__level-2 {
        padding: 0;
        margin: 0;
        float: left;
        width: 75%;
        display: block;
        background: transparent;

        &.active {
            display: block;
        }

        &-inner {
            max-width: pxToRem(1070);
            margin: 0 auto;
            background: white;
            padding: pxToRem(36) pxToRem(40) pxToRem(36) pxToRem(40);
        }

        &-title {
            font-size: pxToRem(16);
            line-height: pxToRem(18);
            color: $black;
            text-transform: uppercase;

            &.active {
                a {
                    color: $pumpkin;
                    font-weight: 600;
                    border-color: $pumpkin;
                    border-width: 5px;
                    margin: 0 0 pxToRem(16) 0;
                }
            }

            a {
                text-decoration: none;
                color: $black;
                display: flex;
                align-items: center;
                width: 100%;
                padding: 0 0 pxToRem(8) 0;
                border-bottom: 5px solid black;
                margin: 0 0 pxToRem(20) 0;
                font-weight: 600;

                &:hover,
                &:focus {
                    color: $pumpkin;
                    border-color: $pumpkin;
                    margin: 0 0 pxToRem(20) 0;
                }
            }
        }

        &-wrapper {
            position: absolute;
            // top: pxToRem(210);
            left: 0;
            right: 0;
            width: auto;
            bottom: auto;
            // padding: pxToRem(34) pxToRem(40) pxToRem(38) pxToRem(40);
            padding: pxToRem(0) pxToRem(40) pxToRem(38) pxToRem(40);
            visibility: visible;
            z-index: 9;
            margin: 0;
            background: transparent;
            
            &.open {
                display: block;
            }

		    &.active {
		        display: none;
		    }
        }

        >li {
            padding: 0;
            margin: 0;
            list-style-type: none;
            float: left;
            width: 100%;

            &:nth-child(3n+1) {
                clear: left;
            }

            &.active {
                a {
                    color: $pumpkin;
                }
            }

            a {
                display: block;
                font-size: pxToRem(16);
                line-height: pxToRem(18);
                font-weight: 500;
                max-width: pxToRem(250);
                padding: 0 0 pxToRem(15) 0;
                text-decoration: none;
                @include sq-transition(color 0.3s);
                @include font-smoothing;
                color: $black;

                &:hover,
                &:focus {
                    color: $pumpkin;
                }

            }
        }
    }
}

.navigation__level-1-item.navigation__level-1-item--has-children .navigation__toggle--lvl2 {
    display: none;
}

.navigation__col-level-2.navigation__col-level-2--has-children .navigation__toggle--lvl3 {
    display: none;
}