/* Module: S */
.page-banner {
    
    width: 100%;
    @include sq-flex-box;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;
    flex-flow: row wrap;
    position: relative;
    z-index: 0;

    &__image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: pxToRem(300);
    }

    &__inner {
        width: 100%;
        position: relative;
    }

    &__header {
        background: $navy;
        width: 100%;
        max-width: 100%;
        padding: pxToRem(30) pxToRem(20) pxToRem(20) pxToRem(20);
        position: relative;
        float: left;

        &:before {
            content: "";
            right: pxToRem(390);
            top: 0;
            height: 100%;
            background: inherit;
            width: pxToRem(344);
            position: absolute;
            @include sq-border-radius(9999px 0 0 9999px);
        }
    }

    &__title {
        color: $white;
        font-size: pxToRem(30);
        line-height: pxToRem(32);
        font-weight: 500;
        @include font-smoothing;
        position: relative;
        z-index: 50;
        margin-top: 0;
        margin-bottom: 0;
    }

    &__content {
        color: $white;
        font-weight: 400;
        width: 100%;
        overflow: hidden;
        font-size: pxToRem(20);
        line-height: pxToRem(23);
        padding: pxToRem(20) pxToRem(20) pxToRem(30) pxToRem(20);
        background: $navy;
        @include font-smoothing;
    }

    &__links {
        position: relative;
        z-index: 50;
        width: 100%;
        margin: pxToRem(15) 0 0 0;
        float: left;

        a {
            border: 2px solid $light-orange;
            padding: pxToRem(9);
            background: $light-orange;
            color: $black;
            font-size: pxToRem(20);
            line-height: pxToRem(20);
            font-weight: 600;
            text-decoration: none;
            display: block;
            width: auto;
            float: left;
            @include sq-transition(background-color 0.4s ease);
            
            &:hover {
               color: $white;
               border-color:  $white;
               background-color: $navy;
            }
        }
    }


    &--short {

        margin: 0 0 pxToRem(10) 0;

        .page-banner__header {

            background: $mint-green;
            padding: pxToRem(20) pxToRem(20) pxToRem(20) pxToRem(20);

            &:before {
                background: inherit;
                right: pxToRem(344.5);
            }
        }

        .page-banner__lower {
            bottom: pxToRem(-65);
        }

        .page-banner__content {
            float: left;
            margin: pxToRem(20) 0 0 0;
            font-size: pxToRem(25);
            line-height: pxToRem(27);
            color: $ocean-blue;
            background: $white;
        }
    }


}