/* Module: S */
body[data-nav-position="top"] {

    &.navigation-open {
        .navigation {
            top: pxToRem(135);
            display: block;
        }    
    }

    .navigation {
        position: absolute;
        left: 0;
        top: pxToRem(-900);
        width: 100%;
        @include sq-transition(all 0.5s ease);
    }

}

.navigation {

    background: $white;
    z-index: 1000;
    @include sq-box-shadow(0 0 10px 0px black);

    &__wrapper {
        width: 100%;
        padding: pxToRem(15) pxToRem(20) 0 pxToRem(20);
        min-height: 100%;
        @include sq-transition((left .3s)); 
	}

	&__toggle {
	    position: absolute;
	    top: 0;
	    right: 0;
	    width: pxToRem(40);
	    height: pxToRem(42);
	    padding: pxToRem(10) pxToRem(10);
	    color: black;
	    background-color: transparent;
        border: none;
	    border-radius: 0;
        -webkit-appearance: none;

        &:before,
        &:after {
            content: '';
            background: black;
            position: absolute;
            width: pxToRem(10);
            height: pxToRem(2);
            @include sq-transition((opacity .3s, transform .3s));
        }

        &:before {
            @include sq-transform(rotate(45deg));
            left: pxToRem(12);
            top: pxToRem(20);
        }

        &:after {
            @include sq-transform(rotate(-45deg));
            left: pxToRem(18);
            top: pxToRem(20);
        }

        &.active {
            &:before {
                @include sq-transform(rotate(-40deg));
            }

            &:after {
                @include sq-transform(rotate(40deg));
            }

        }
	}

    &__info {
        display: none;
    }

    &__level-1 {
        @extend %clearfix;
        padding: 0;
        margin: 0;

        >li {
	        padding: 0;
	        margin: 0;
            list-style-type: none;
            
            &:last-child {
                a:after {
                    display: none;
                }
            }
        }

        &-link {
            position: relative;
            z-index: 500;

        	&.parent {
        		a {
                    padding-right: pxToRem(40);
                    color: $black;
        		}
        	}

        	a {
        		display: block;
                font-size: pxToRem(16);
                line-height: pxToRem(19);
        		text-decoration: none;
                padding: pxToRem(10) 0 pxToRem(14);
                position: relative;
                color: $black;

                &:after {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: pxToRem(1);
                    background: $black;
                    opacity: 0.2;
                    content: "";
                }
        	}
        }
    }

    &__level-2 {
        @extend %clearfix;
        padding: 0 20px;
        margin: 0 -20px;
        position: relative;
        background: $darker-navy;
        display: none;

        &.active {
            display: block;
        }

        &-inner {
            @extend %clearfix;
        }

        &-title {
            position: relative;
            padding: pxToRem(8) 0 pxToRem(8) 0;
            z-index: 100;

            a {
                color: $white;
                text-decoration: none;
            }

            button {
                right: 0;
                height: pxToRem(38);

                &:before,
                &:after {
                    background: $white;
                }
            }
        }

        &-wrapper {
            padding: pxToRem(20) pxToRem(20) pxToRem(20) pxToRem(20);
            margin: 0 pxToRem(-20);
            background: $navy;
            display: none;

		    &.active {
		        display: block;
		    }
        }

        >li {
	        padding: 0;
	        margin: 0;
	        list-style-type: none;

        	a {
        		display: block;
        		text-decoration: none;
        		color: $white;
        		padding: pxToRem(10) 0;
        	}
        }
    }

}

.navigation__col-level-2:last-child .navigation__level-2-title {
    padding-bottom: 0;
}

.navigation__level-1-item .navigation__toggle--lvl2 {
    display: none;
}
.navigation__level-1-item.navigation__level-1-item--has-children .navigation__toggle--lvl2 {
    display: block;
}

.navigation__col-level-2 .navigation__toggle--lvl3 {
    display: none;
}

.navigation__col-level-2.navigation__col-level-2--has-children .navigation__toggle--lvl3 {
    display: block;
}