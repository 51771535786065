/* Module: S */
.banner-carousel {
    
    position: relative;

    &__slide-wrapper {
        position: relative;
    }

    &__image {
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
        width: 100%;
        height: pxToRem(300);
    }

    &__inner {
        width: 100%;
        padding: 0 pxToRem(20);
        @include sq-flex-box;
        justify-content: stretch;
        align-items: flex-end;
        height: 100%;
        background: $navy;
    }

    &__wrapper {
        width: 100%;
    }

    &__content-wrapper {
        background: $navy;
        width: 100%;
        padding: pxToRem(30) 0 pxToRem(30) 0;
        position: relative;
        z-index: 150;
    }

    &__title {
        font-size: pxToRem(30);
        line-height: pxToRem(32);
        color: $white;
        position: relative;
        z-index: 50;
        margin: 0 0 pxToRem(20) 0;
        min-height: pxToRem(95);
        @include font-smoothing;
    }

    &__content {
        font-size: pxToRem(20);
        line-height: pxToRem(22);
        margin: 0 0 pxToRem(40) 0;
        color: $white;
        position: relative;
        z-index: 50;
        @include font-smoothing;
        display: none;
    }

    &__link {
        
        position: relative;
        z-index: 50;
        @extend %clearfix;
        padding: 0 0 pxToRem(5) 0;

        a {
            background-color: $light-orange;
            color: $navy;
            padding: pxToRem(8) pxToRem(10);
            text-decoration: none;
            font-weight: bold;
            display: block;
            width: auto;
            float: left;
            font-size: pxToRem(16);
            line-height: pxToRem(19);
            border: 2px solid $light-orange;
            @include font-smoothing;
            @include sq-transition(background-color 0.4s ease);

            &:focus,
            &:hover {
                background-color: $navy;
                color: $white;
                border-color: $white;
            }
        }
    }

    &__play {
        
        width: pxToRem(30);
        height: pxToRem(30);
        position: relative;
        margin-right: pxToRem(5);

        button {
            @include clear-default-appearance;
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;


            &:before {
                content :"";
                width: pxToRem(3);
                height: pxToRem(15);
                border: none;
                background: $white;
                top: pxToRem(7);
                position: absolute;
                left: pxToRem(10);
                top: pxToRem(7);     
                border: none;
            }

            &:after {
                content: "";
                width: pxToRem(3);
                height: pxToRem(15);
                border: none;
                background: $white;
                position: absolute;
                right: pxToRem(9);
                top: pxToRem(7);
            }

            &.active {
                &:after {
                    display: none;
                }

                &:before {
                    left: pxToRem(10);
                    top: pxToRem(9);            
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 6px 0 6px 10px;
                    border-color: transparent transparent transparent #ffffff;
                    background: transparent;
                }
            }
        }

    }

    &__nav {
        position: absolute;
        right: pxToRem(20);
        bottom: pxToRem(30);
        width: 40vw;
        @include sq-flex-box;
        flex-flow: row wrap;
        align-content: flex-end;
        align-items: flex-end;
        justify-content: flex-end;
    }

    &__controls {
        width: 100%;
        max-width: pxToRem(300);
        @include sq-flex-box;
        flex-flow: row wrap;
        justify-content: flex-end;
        align-content: center;
        align-items: center;
        float: right;
    }

    &__arrows {
        margin: 0 pxToRem(80) 0 0;
        display: none;
        @include sq-border-radius(200px);
        overflow: hidden;
        background: $light-orange;
        
        .slick-arrow {
            @include clear-default-appearance;
            position: relative;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0;
            text-indent: -9999px;
            background: $light-orange;
            width: pxToRem(90);
            height: pxToRem(90);

            &:before,
            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: pxToRem(3);
                width: pxToRem(28);
                background: $black;
                @include sq-border-radius(200px);
                @include sq-transition(background-color 0.4s ease);
            }

            &:hover,
            &:focus {
                &:before,
                &:after {
                    background: $white;
                }
            }

            &.slick-prev {
                border-radius: 200px 0 0 200px;
                -webkit-border-radius: 200px 0 0 200px;
                -moz-border-radius: 200px 0 0 200px;

                &:before {
                    @include sq-transform(rotate(-45deg));
                    top: pxToRem(32);
                    left: pxToRem(33);
                }

                &:after{
                    @include sq-transform(rotate(45deg));
                    top: pxToRem(51);
                    left: pxToRem(33);
                }
            }

            &.slick-next {
                border-radius: 0 200px 200px 0;
                -webkit-border-radius: 0 200px 200px 0;
                -moz-border-radius: 0 200px 200px 0;

                &:before {
                    @include sq-transform(rotate(-135deg));
                    top: pxToRem(32);
                    left: pxToRem(33);
                }

                &:after{
                    @include sq-transform(rotate(135deg));
                    top: pxToRem(51);
                    left: pxToRem(33);
                }
            }
        }
    }

    .slick-dots {
        width: auto;
        margin: 0;
        padding: 0;
        float: right;

        li {

            margin: 0 pxToRem(10) 0 0;
            padding: 0;

            &.slick-active {
                button {
                    background-color: $light-orange;
                }
            }

            button {
                @include clear-default-appearance;
                width: pxToRem(13);
                line-height: pxToRem(13);
                background: $white;
                @include sq-border-radius(200px);
                @include sq-transition(background-color 0.4s ease);
                text-indent: -9999px;

                &:hover,
                &:focus {
                    background-color: $light-orange;
                }
            }
        }
    }
}