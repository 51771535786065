/* Module: S */
.breadcrumbs {
    li {
		display: block;
		position: relative;
		padding-right: pxToRem(30);

		&:nth-last-child(2):not(:last-child) {

			&:before {
				display: none;
			}

        }
        
        &:after {
            display: block;
        }

		&:last-child {
			&:after {
				display: none;
			}
		}
	}
}