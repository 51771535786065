/* Module: S */

.news {
    background-color: $white;
    position: relative;
    width: 100%;
    overflow: hidden;

    &__wrapper {
        max-width: $page__max-width;
        margin: 0 auto;
    }

    &__column {
        background-color: $white;
        color: $dark-cyan--font;
        @include font-smoothing;
        padding: pxToRem(30) 0;


        &:last-child {
            background-color: $white-two;
            position: relative;
            &:before {
                content: "";
                position: absolute;
                /* fill vertically */
                top: 0;
                bottom: 0;
                width: 9600px;
                left: 100%;
                background: $white-two;
            }

            h2 {
                color: $dark-cyan--font;
                font-weight: normal;
                font-family: $secondary__font-family;
                padding-bottom: pxToRem(10);
            }

            h3 {
                a {
                    color: $dark-cyan--font;
                    font-weight: normal;
                    &:hover {
                        color: $black;
                        text-decoration: none;
                    }
                }
            }

            .news {
                &__item {
                    padding: pxToRem(18) pxToRem(20);
                }

                &__date {
                    color: $dark-cyan--font;
                }

                &__link {
                    a {
                        color: $dark-cyan--font;
                        font-weight: normal;

                        &:hover {
                            text-decoration: none;
                            color: $dark-cyan--font;
                        }
                    }
                }
            }
        }
    }

    &__blocks {
        @extend %clearfix;
        position: relative;
    }

    &__list {
        @extend %clearfix;
        position: relative;
    }

    &__item {
        float: left;
        padding: pxToRem(20) pxToRem(20);
        width: 100%;
    }

    &__image {
        padding-bottom: pxToRem(18);
        
        img {
            width: 100%;
            border-radius: pxToRem(10);
        }
    }

    &__date {
        font-size: pxToRem(12);
        font-weight: 500;
        line-height: 2.08;
        text-transform: uppercase;
        color: $dark-cyan--font;
        font-weight: bold;
    }

    &__text {
        font-size: pxToRem(14);
        line-height: 1.64;
        color: $black-three;
        padding-top: pxToRem(10);
    }

    &__link {
        padding: pxToRem(10) pxToRem(20) pxToRem(20) pxToRem(20);

        a {
            display: inline-block;
            position: relative;
            text-decoration: none;
            color: $dark-cyan--font;
            font-size: pxToRem(18);
            font-weight: normal;
            line-height: 1.39;

            &:hover {
                text-decoration: none;
                color: $dark-cyan--font;
                .icon-chevron {
                    background: $orange;
                }
            }

        }
    }

    h2 {
        font-size: pxToRem(40);
        font-weight: normal;
        margin: 0;
        padding: pxToRem(20) pxToRem(20);
        color: $dark-cyan--font;
        position: relative;
        z-index: 10;
    }

    h3 {
        font-size: pxToRem(18);
        font-weight: normal;
        line-height: 1.39;
        margin: 0;

        a {
            text-decoration: none;
            color: $orange--font;

            &:hover {
                text-decoration: underline;
                color: $orange--font;
            }
        }
    }
    .icon-chevron {
        background: $dark-cyan;
        &:after {
            color: $dark-cyan;
           
            
        }
    }
}