/* Module: S */
.article-title {
    padding: pxToRem(41.25) pxToRem(30) pxToRem(45) pxToRem(67.5);
    margin: 0 0 pxToRem(52.5) pxToRem(5);
    max-width: 900px;

    &__content {
        font-size: pxToRem(30);
        line-height: pxToRem(33.75);
    }

    &__detail {
        font-size: pxToRem(13.5);
        line-height: pxToRem(18);
    }
}