/* Module: S */
body.navigation-open {

    .header__toggle-nav {
    
        span {

            &:nth-child(2n){
                @include sq-transform(rotate(45deg));
                top: pxToRem(13);
            }

            &:nth-child(3n){
                display: none;
            }

            &:nth-child(4n){
                @include sq-transform(rotate(-45deg));
                top: pxToRem(13);
            }
        }
    }
    
}

.header {

    &__top {
        position: relative;
        z-index: 5000;
    }

    &__logo {

        width: pxToRem(150);

        img {
            display: none;
        }

        a {
            text-decoration: none;
        }

        &-text-mobile {
            display: block;
            font-size: pxToRem(19);
            line-height: pxToRem(23);
            color: $black;
            font-weight: 600;
            text-decoration: none;
        }
    }

    &__toggle-btns {
        width: pxToRem(200);
    }

    &__toggle-nav {
        @include clear-default-appearance;
        position: relative;
        width: pxToRem(30);
        height: pxToRem(30);
        float: right;

        &:hover,
        &:focus {
            span {
                &:nth-child(2n),
                &:nth-child(3n),
                &:nth-child(4n) {
                    background: $navy;
                }
            }
        }

        span {

            &:nth-child(2n),
            &:nth-child(3n),
            &:nth-child(4n) {
                background: $black;
                width: 100%;
                height: pxToRem(4);
                display: block;
                position: absolute;
                left: 0;
            }

            &:nth-child(2n){
                top: pxToRem(5);
            }

            &:nth-child(3n){
                top: pxToRem(13);
            }

            &:nth-child(4n){
                top: pxToRem(21);
            }
        }
    }

    &__toggle-search {
        @include clear-default-appearance;
        position: relative;
        width: pxToRem(30);
        height: pxToRem(30);
        float: right;
        margin-right: pxToRem(15);
        padding: pxToRem(3);

        &:hover,
        &:focus {
            svg {
                color: $navy;
            }
        }

        &.active {

            &:before,
            &:after {
                content: "";
                position: absolute;
                display: block;
                width: pxToRem(25);
                height: pxToRem(3);
                background: $black;
                left: pxToRem(2);
                top: pxToRem(14);
            }

            &:before {
                @include sq-transform(rotate(45deg));
            }

            &:after {
                @include sq-transform(rotate(-45deg));
            }

            svg {
                display: none;
            }
        }

        svg {
            width: 100%;
            height: 100%;
            display: block;
        }
    }

    &__inner {
        @include sq-flex-box;
        width: 100%;
        flex-flow: row wrap;
    }

    &__details {
        width: 100%;
        @include sq-flex-box;
        flex-flow: row nowrap;
        align-content: stretch;
        align-items: stretch;
        justify-content: flex-end;
    }

    &__left {
        order: 2;
        max-width: 100%;
        width: 100%;
        padding: pxToRem(18) pxToRem(20);
        background: $light-grey;
        @include sq-flex-box;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        position: relative;
    }

    &__right {
        order: 1;
        max-width: 100%;
        width: 100%;
        @include sq-flex-box;
        flex-flow: row wrap;
        justify-content: flex-end;
    }

    &__my-heti {
        
        width: auto;
        background: $navy;
        @include sq-flex-box;
        flex-flow: row wrap;
        align-content: center;
        align-items: center;
        order: 2;

        a {
            font-size: pxToRem(14);
            line-height: pxToRem(19);
            color: $white;
            font-weight: 500;
            text-decoration: none;
            padding: pxToRem(10) pxToRem(20) pxToRem(10) pxToRem(8);

            &:focus,
            &:hover {
                text-decoration: underline;
            }

            &[target="_blank"] {
                &:before {
                    content: "";
                    background: url("../mysource_files/icon-external.svg");
                    background-repeat: no-repeat;
                    background-position: center center;
                    display: inline-block;
                    vertical-align: middle;
                    width: pxToRem(16);
                    height: pxToRem(16);
                    margin: 0 pxToRem(12) 0 0;
                }
            }
        }
    }

    &__contact {
        @include sq-flex-box;
        flex-flow: row nowrap;
        align-content: stretch;
        align-items: stretch;
        justify-content: stretch;
        order: 1;
        background: $light-orange;

        a {
            margin-right: pxToRem(10);
            color: $black;
            text-decoration: none;
            padding: pxToRem(10) pxToRem(8);
            font-size: pxToRem(14);
            line-height: pxToRem(19);
            position: relative;

            background: $light-orange;
        
            &:after {
                content: "";
                width: 1100px;
                z-index: 0; 
                background: $light-orange;
                position: absolute;
                right: pxToRem(88);
                top: 0;
                height: 100%;
            }
    

            &:focus,
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__search {
        padding: pxToRem(24) pxToRem(15) pxToRem(21) pxToRem(15);
        background: $mint-green;
        position: relative;
        position: absolute;
        top: pxToRem(140);
        left: 0;
        width: 100%;
        z-index: 50;
        @include sq-transition(top 0.4s ease);
        display: none;

        &.active {
            display: block;
        }

        form {
            position: relative;
            z-index: 50;
            @include sq-flex-box;
            flex-flow: row nowrap;
            align-content: center;
            align-items: center;
            justify-content: space-between;
            @include sq-border-radius(999px);
            background: $white;
            padding: 0 pxToRem(15);

            input[type="text"] {
                margin: 0;
                @include clear-default-appearance;
                background: white;
                padding: pxToRem(14) pxToRem(10);
                font-size: pxToRem(16);
                line-height: pxToRem(18);
                width: 100%;
                @include sq-border-radius(999px);

                &.active {
                    max-width: 100%;
                }
            }

            .sq-form-question {
                order: 1;
                margin: 0;
                width: 100%;
            }

            .sq-form-control {
                order: 2;
                max-width: pxToRem(50);

                span {
                    display: none;
                }

                button {
                    @include clear-default-appearance;
                    @include sq-flex-box;
                    color: white;
                    width: 100%;
                    align-content: center;
                    align-items: center;
                    @include sq-flex-box;
                    align-content: center;
                    align-items: center;
                    flex-flow: row nowrap;
                    padding: pxToRem(10) pxToRem(5);
                    font-weight: 500;
                    @include font-smoothing;

                    span {
                        order: 2;
                        margin-left: pxToRem(10);
                        margin-right: pxToRem(50);
                    }
                }

                svg {
                    width: pxToRem(20);
                    height: pxToRem(20);
                    color: $black;
                    // @include sq-transform(rotate(90deg));
                }
            }
        }

        &:after {
            content: "";
            width: 1100px;
            z-index: 0; 
            background: $mint-green;
            position: absolute;
            left: pxToRem(118);
            top: 0;
            height: 100%;
        }

    }


    &__accessibility {
		position: absolute;
		width: 100%;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 500;
        height: pxToRem(19);

        div {
        	text-align: center;
            text-transform: uppercase;
            font-size: pxToRem(14);
            font-weight: 500;
            color: black;

            a {
                opacity: 0;
                @include sq-transition(opacity .3s, top .3s);
                text-decoration: none;
                position: absolute;
                top: pxToRem(-19);
                margin-left: pxToRem(-80);
                min-width: pxToRem(160);
            	color: black;

                &:focus {
                    opacity: 1;
                	top: 0;
                }
            }

            span {
                opacity: 0;
                @include sq-transition(visibility .3s);
                position: absolute;
                left: 0;
                right: 0;
                display: block;

                &.active {
                    opacity: 1;
                }
            }

        }

	}
    

}