/* Module: S */
.landing-blocks {

    &__item {
        width: 33.3%;
        max-width: 100%;
        height: 32vw;
        max-height: 100%;
    }

    &__title {
        font-size: pxToRem(26);
        line-height: pxToRem(29);
        padding: 0 6vw;
    }

}