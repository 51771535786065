/* Module: S */
.feature-bubbles {
    
    &__item {

        width: 25%;
        margin: 0 0 pxToRem(25) 0;

        > a {
            @include sq-flex-box;
            justify-content: center;
            flex-flow: row wrap;
            max-width: 100%;
        }
    }

    &__wrapper {
        align-items: flex-start;
    }

    &__img {
        img {
            max-width: 70%;
        }
    }

    &__item-title {
        max-width: 100%;
        padding: 0;
        max-width: 70%;
    }
}