/* Module: S */
.feature-note {

    width: 100%;
    background: $navy;
    margin: pxToRem(19) 0;
    padding: pxToRem(42) 0 pxToRem(53) 0;
    position: relative;

    &--expand {
        margin-left: pxToRem(-20);
        margin-right: pxToRem(-20);
        width: auto;
    }

    &:before {
        content: "";
        position: absolute;
        left: pxToRem(-390);
        top: 0;
        width: pxToRem(450);
        height: 100%;
        background-image: url('../mysource_files/bg-note-left.png');
        background-repeat: no-repeat;
        background-size: 170%;
        background-position: left center;
        background-color: $navy;
        background-position: pxToRem(-238) pxToRem(-100);
        z-index: 10;
        display: none;
    }

    &:after {
        content: "";
        position: absolute;
        right: pxToRem(-130);
        top: 0;
        width: pxToRem(9000);
        height: 100%;
        background-image: url('../mysource_files/bg-note-right.png');
        background-repeat: no-repeat;
        background-size: pxToRem(280);
        background-color: $navy;
        background-position: right pxToRem(-15);
        @include sq-border-radius(0 9999px 9999px 0);
        overflow: hidden;
        z-index: 0;
        display: none;
    }

    &__inner {
        padding: 0 pxToRem(20);
        position: relative;
        z-index: 50;
    }
    
    &__title {
        color: $white;
        font-size: pxToRem(18.75);
        line-height: pxToRem(28);
        font-weight: bold;
        @include font-smoothing;
    }

    &__content {
        margin: pxToRem(31) 0 0 0;
        color: $white;
        
        line-height: pxToRem(24);
        @include font-smoothing;
    }

    &__left,
    &__right {
        @extend %clearfix;
        width: 100%;
        margin: 0;
        font-weight: 500;
    }

    &__left {
        margin: 0 0 pxToRem(15) 0;
    }

}