/* Module: S */
.footer {

    &__inner {
        // max-width: $page__max-width;
        max-width: 1008px;
    }


    &__lower {
        flex-flow: row nowrap;
        padding: pxToRem(55) pxToRem(20) 0 pxToRem(20);
    }

    &__right {
        flex-flow: row nowrap;
        max-width: pxToRem(600);
        width: 100%;
    }

    &__logo {

        margin: 0;
        
        a {
            max-width: pxToRem(277.5);
        }
    }

    &__col {
        padding: 0 pxToRem(20) 0 0;
        margin: 0 0 pxToRem(40) 0;

        &-title {
            a {
                border-bottom: 5px solid $light-orange;
                padding: 0 0 pxToRem(11.25) 0;
                font-size: pxToRem(12);
                line-height: pxToRem(14.25);
            }
        }

        ul {
            li {
                margin: 0 0 pxToRem(11.25) 0;

                a {
                    font-size: pxToRem(12);
                    line-height: pxToRem(14.25);
                }
            }
        }

        &--social {
            ul {

                li {
                    margin: 0 0 0 pxToRem(10);
                }
            }
        }

    }

    &__enews {

        .footer__inner {
            position: relative;
        }
        
        &-wrapper {
            padding-left: pxToRem(30);
            // max-width: pxToRem(560);
            max-width: pxToRem(490);
            width: 70%;
            padding: 0 pxToRem(20) 0 0;
            order: 3;
        }

        &-title {
            font-size: pxToRem(30);
            line-height: pxToRem(33.75);
            text-align: right;
            max-width: pxToRem(482);
            width: 100%;
            margin: 0;
            padding-right: pxToRem(30);
            font-weight: 500;
        }

        form {
            @include sq-flex-box;
            align-items: stretch;
        }

        .footer__inner {
            justify-content: space-between;
        }
    }

    input[type="text"],
    input[type="email"] {
        max-width: pxToRem(355);
        margin: 0;
        font-size: pxToRem(18.75);
        padding: pxToRem(10) ;
    }

    input[type="submit"] {
        margin: 0 0 0 pxToRem(7);
        width: auto;
        font-size: pxToRem(15);
        line-height: pxToRem(10);
        padding: pxToRem(15) pxToRem(16);
        flex-shrink: 0;
    }

    &__scroll {
        @include sq-flex-box;
        flex-flow: row wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        position: absolute;
        right: pxToRem(-50);
        top: pxToRem(25);

        button {
            width: pxToRem(27);
            height: pxToRem(27);

            &:before,
            &:after {
                content: "";
                width: pxToRem(11.25);
                height: pxToRem(2.25);
                background: $navy;
                position: absolute;
                top: 0;
                left: 0;
                @include sq-border-radius(999px);
            }


            &:before {
                @include sq-transform(rotate(-45deg));
                left: pxToRem(4.5);
                top: pxToRem(11.25);
            }

            &:after {
                @include sq-transform(rotate(45deg));
                left: pxToRem(11.25);
                top: pxToRem(11.25);
            }
        }
    }

}

.home {
    .footer__enews {
        &-wrapper {
            max-width: pxToRem(450);
        }

        &-title {
            max-width: pxToRem(525);
        }
    }
}