/* Module: S */
.testimonial {

    flex-flow: row nowrap;

    margin: pxToRem(10.5) 0 pxToRem(5) 0;

    &__img {
    	width: pxToRem(93.75);
        height: pxToRem(93.75);
        margin: 0 pxToRem(7.875) 0 0;
    }

    &__content {
        width: 75%;
        font-size: pxToRem(13.5);
        line-height: pxToRem(20);
    }
}