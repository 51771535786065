/* Module: S */
.news-landing {

    width: 100%;

    &--expand {
        margin-left: pxToRem(-20);
        margin-right: pxToRem(-20);
        width: auto;
    }

    &__inner {
        @include sq-flex-box;
        justify-content: center;
        align-content: flex-start;
        align-items: stretch;
        flex-flow: row wrap;
        width: 100%;
        max-width: $page__max-width;
    }

    &__left {
        background: $mint-green;
        padding: pxToRem(20) pxToRem(20) pxToRem(20) pxToRem(20);
        width: 100%;
        position: relative;
    }

    &__item {
        @include sq-flex-box;
        flex-flow: row wrap;
        width: 100%;
        align-content: flex-start;
        align-items: flex-start;

        &:last-child {
            .news-landing__content {
                margin-bottom: 0;
                padding-bottom: 0;
                border: none;
            }
        }
    }

    &__content {
        width: 100%;
        border-bottom: 1px solid $mint-green;
        padding: 0 0 pxToRem(10) 0;
        margin: 0 0 pxToRem(15) 0;
    }

    &__date {
        color: $navy;
        font-size: pxToRem(15);
        line-height: pxToRem(19);
    }

    &__item-title {
        font-size: pxToRem(18.75);
        line-height: pxToRem(23);
        color: $black;
        margin: pxToRem(6) 0;
    }

    &__summary {
        @extend %clearfix;
        font-size: pxToRem(15);
        line-height: pxToRem(19);
        color: $black;
        margin: pxToRem(5) 0 0 0;

        a {
            text-decoration: none;
            color: $ocean-blue;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }

    &__img {

        width: pxToRem(130);
        height: pxToRem(130);
        display: none;

        img {
            width: 100%;
        }
    }

    &__link {
        width: 100%;
        margin: pxToRem(30) 0 0 0;
        position: relative;
        z-index: 50;
        @extend %clearfix;

        a {
            color: $white;
            background: $navy;
            font-size: pxToRem(16);
            line-height: pxToRem(16);
            font-weight: 600;
            // border: 2px solid $navy;
            @include font-smoothing;
            text-decoration: none;
            display: block;
            float: left;
            width: auto;
            padding: pxToRem(11);
            @include sq-transition(background-color 0.4s ease);

            &:hover,
            &:focus {
                background: $orange;
                // border: 2px solid $navy;
                color: $white;
            }
        }
    }

    &__right {
        width: 100%;
        background: $light-grey;
        padding: pxToRem(38) pxToRem(20) pxToRem(30) pxToRem(20);
        @include sq-flex-box;
        justify-content: stretch;
        align-content: center;
        align-items: center;
        flex-flow: row wrap;
    }

    &__title {
        color: $white;
        font-size: pxToRem(38);
        line-height: pxToRem(40);
        font-weight: 300;
        width: 100%;
        position: relative;
        z-index: 50;
    }

}