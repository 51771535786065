/* Module: S */
.article-title {

    background: $light-grey;
    width: 100%;
    padding: pxToRem(30);

    &__content {
        color: $black;
        font-size: pxToRem(30);
        line-height: pxToRem(35);
        margin: 0 0 pxToRem(18) 0;
    }

    &__detail {
        font-size: pxToRem(18);
        line-height: pxToRem(24);
        color: $black;
        p {
            margin-top: 0;
            margin-bottom: 0;
        }
        a {
            color: $ocean-blue;
            text-decoration: none;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }

}