/* Module: S */
.featured-tiles {
    padding-bottom: pxToRem(60);

    &__wrapper {
        max-width: $page__max-width;
        margin: 0 auto;
        position: relative;

        &:before {
            z-index: 9;
            content: '';
            position: absolute;
            bottom: -92px;
            left: 115px;
            width: 200px;
            height: 100px;
            border-top-left-radius: 200px;
            border-top-right-radius: 200px;
            background-color: white;
        }

        &:after {
            z-index: 9;
            content: '';
            position: absolute;
            bottom: -90px;
            left: 24px;
            width: 100px;
            height: 100px;
            border-radius: 100%;
            background-color: $turquoise-blue;
        }

    }

    &__item {
        float: left;
        padding: pxToRem(10) pxToRem(20);
        width: 100%;

        h3 {
            background-color: none;
            font-size: pxToRem(18);
            font-weight: normal;
            line-height: 1.38;
            padding: pxToRem(16) 0 pxToRem(15) 0;
            margin: 0;
            color: $black;
            @include font-smoothing;
            @include sq-transition(background-color 0.3s);
        }

        a {
            text-decoration: none;

            &:hover {
                .icon-chevron {
                    background: $orange;
                }
                h3 {
                    color: $black-three;
                    text-decoration: underline;
                    &:after {
                        text-decoration: none;
                        right: -2px;
                    }
                }
            }
        }
    }

    &__image {
        height: pxToRem(258);
        width: 100%;
        display: block;
        position: relative;
        background-size: cover;
        background-position: center;
        border-radius: pxToRem(10);
    }

    &-landing {
        padding: pxToRem(30) 0;

        .featured-tiles {
            &__inner {
                margin: 0 pxToRem(-20);
                @include sq-flex-box();
                @include sq-flex-wrap(wrap);
            }
        }
    }
}

.section--programs {
    h2.section-title {
        color: $black;
    }
}

.section {
    h2 {
        font-size: pxToRem(30) !important;
    }
}

h2.section-title {
    font-size: pxToRem(30) !important;
}
