/* Module: S */
.breadcrumbs {
    
    width: 100%;
    @include sq-flex-box;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 150;

    &:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        background: $black;
        content: "";
        height: 100%;
        opacity: 0.4;
    }

    &__inner {
        width: 100%;
        padding: pxToRem(10) pxToRem(20);
        position: relative;
        z-index: 50;
    }

	a { 
        font-size: pxToRem(14);
        line-height: pxToRem(17);
        text-decoration: none;
        color: $white;
        @include font-smoothing;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
	}

    ul {
		@include sq-list-reset;
		padding: 0;
		margin: 0;
	}

	li {
		@include sq-list-reset;
		padding: 0;
		margin: 0;
		float: left;
		display: none;

		&:nth-last-child(2):not(:last-child) {
			display: block;

			&:before {
                content: "<";
                display: inline-block;
                vertical-align: middle;
                padding: 0 pxToRem(5);
                color: $white;
            }
        }
        
        &:after {
			position: absolute;
			top: 0;
			bottom: 0;
			right: pxToRem(9);
			margin-top: 0;
            content: ">";
            color: $white;
            font-size: pxToRem(14);
            line-height: pxToRem(17);
			width: pxToRem(10);
			height: pxToRem(11);
            display: inline-block;
            display: none;
            font-weight: 400;
		}

    }
    
    span {
        font-weight: 400;
        font-size: pxToRem(14);
        line-height: pxToRem(17);
        color: $white;
        @include font-smoothing;
    }


}