/* Module: S */
.service-boxes {
    
    padding: 0;

    &__item {
        width: 25%;
        margin: 0;
        height: pxToRem(250);
        align-items: flex-start;
        padding: pxToRem(70) pxToRem(20) pxToRem(20) pxToRem(20);

        &--no-link {
            padding-top: pxToRem(70);
            align-content: flex-start;
        }

    }


    &__title {
    	line-height: pxToRem(21.75);
    }

    &__content {
    	line-height: pxToRem(18.75);
    	ul li a {
    		font-size: pxToRem(15);
    		line-height: pxToRem(18.75);
    	}
    }

    &__social {
    	ul li a {
    		height: pxToRem(16.5);
    		width: pxToRem(16.5);
    	}
    	.service-box__icon {
    		height: pxToRem(16.5);
    		width: pxToRem(16.5);
    	}
    }
}