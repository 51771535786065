/* Module: S */
.news-landing {

    &--expand {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }

    &__inner {
        flex-flow: row nowrap;
    }

    &__title {
        font-size: pxToRem(60);
        line-height: pxToRem(62.25);
        max-width: pxToRem(150);
    }

    &__link {
        margin: pxToRem(25) 0 0 0 !important;
    }

    &__left {
        padding: pxToRem(30) 0 pxToRem(30) 0;
        max-width: pxToRem(303.75);

        &:before {
            background: $mint-green;
            content: "";
            left: pxToRem(-202);
            width: pxToRem(202.5);
            height: 100%;
            position: absolute;
            top: 0;
            @include sq-border-radius(999px 0 0 999px);
        }
    }

    &__right {
        // padding: pxToRem(38) pxToRem(38) pxToRem(11) pxToRem(55);
        padding: pxToRem(28.5) pxToRem(28.5) pxToRem(8.25) pxToRem(41.25);
    }

    &__img {
        display: block;
        width: pxToRem(97.5);
        height: pxToRem(97.5);
    }

    &__link {
        margin: pxToRem(60) 0 0 0;

        a {
            padding: pxToRem(11);
            font-size: pxToRem(15);
            line-height: pxToRem(15);
        }
    }

    &__date {
        line-height: pxToRem(18.75);
    }

    &__item-title {
        line-height: pxToRem(21);
        margin: pxToRem(6) 0;
    }

    &__summary {
        line-height: pxToRem(18.75);
    }

    &__content {
        margin: 0 0 pxToRem(15) pxToRem(35);
    }

    &__item {
        flex-flow: row nowrap;
    }

}