/* Module: S */
.feature-bubbles {
    width: 120%;
    &__title {
        text-align: left;
        font-size: pxToRem(22.5);
    }

    &__item {
        width: 22%;
        margin: 0;

        > a {
            max-width: 100%;
        }
    }

    &__img {

        img {
            max-width: 100%;
        }
    }

    &__item-title {
        font-size: pxToRem(13.5);
        line-height: pxToRem(15);
        margin: pxToRem(15) 0 0 0;
        padding: 0;
        max-width: 100%;
    }


    &__links {
        margin: pxToRem(58.5) 0 pxToRem(41.25) 0;
        justify-content: flex-start;

        a{
            padding: pxToRem(11.25) pxToRem(27.75) pxToRem(15) pxToRem(27.75);
            font-size: pxToRem(32.25);
            line-height: pxToRem(32.25);
        }
    }

    
}