> h1:first-of-type {
  margin-top: 0;
  margin-bottom: 0.5em;
}
> h1:first-of-type {
  line-height: 1;
}
/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $ocean-blue;
  @include font-smoothing;
  &:not(h1, .search-result__heading, .map__description-heading, .feature-box__heading, .feature-tiles__heading, .toc__heading) {
    margin-bottom: 0.75rem;
    //margin-top: 4rem;
  }
}

h1 {
  font-size: pxToRem(22.5);
  font-weight: normal;
  font-family: $secondary__font-family;
  line-height: 1.41;
}
h2 {
  font-size: pxToRem(21);
  font-weight: normal;
  &:not(.search-result__heading, .map__description-heading, .feature-box__heading, .feature-tiles__heading, .toc__heading) {
    margin-top: 3rem;
  }
}
h3 {
  font-size: pxToRem(15);
  font-weight: normal;
  &:not(.search-result__heading, .map__description-heading, .feature-box__heading, .feature-tiles__heading, .toc__heading) {
    margin-top: 2rem;
  }
}
h4 {
  font-size: pxToRem(13.5);
  font-weight: bold;
  &:not(.search-result__heading, .map__description-heading, .feature-box__heading, .feature-tiles__heading, .toc__heading) {
    margin-top: 2rem;
  }
}
h5 {
  font-size: pxToRem(12);
  font-weight: bold;
  &:not(.search-result__heading, .map__description-heading, .feature-box__heading, .feature-tiles__heading, .toc__heading) {
    margin-top: 1.5rem;
  }
}
h6 {
  font-size: pxToRem(12);
  font-weight: bold;
  &:not(.search-result__heading, .map__description-heading, .feature-box__heading, .feature-tiles__heading, .toc__heading) {
    margin-top: 1.5rem;
  }
}

/* Tables */
/* stylelint-disable */
.responsive__table {
  width: 100%;
  overflow: auto;
  margin: 0.75em 0;
}

p {
  font-size: pxToRem(16);
  line-height: 1.64;
}

table {
  width: 100%;
  min-width: pxToRem(582);

  ul,
  ol {
    // Unfortunately no other way to override unordered list mixin with &:not(...)
    > li {
      margin-bottom: 0.25rem !important;
    }
  }
}

tbody {
  tr {
    border-bottom: 1px solid $white-five;
    @include sq-transition(0.15s background-color linear);

    &:nth-child(odd) {
      background-color: $table-row-odd__bg;

      &:hover {
        background-color: $table-row-odd__bg--hover;
      }
    }

    &:nth-child(even) {
      background-color: $table-row-even__bg;

      &:hover {
        background-color: $table-row-even__bg--hover;
      }
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}

caption {
  @include sq-font-size(0.8);
  font-style: italic;
}

th {
  font-size: pxToRem(14);
  line-height: 1.45;
  text-align: left;
  color: $table-heading__color;
  background-color: $orange;
  border-right: 1px solid #fff;
  padding: pxToRem(18) pxToRem(20) pxToRem(18);
  white-space: nowrap;
  font-weight: normal;
  font-family: $secondary__font-family;
  @include font-smoothing;

  &:first-child {
    border-radius: pxToRem(50) 0 0 pxToRem(50);
  }

  &:last-child {
    border-right: 0;
    border-radius: 0 pxToRem(50) pxToRem(50) 0;
  }
}

td {
  padding: pxToRem(20) pxToRem(24);

  ul,
  ol {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@include unordered-list($black);
@include ordered-list($black);

/* Quotes */
blockquote {
  position: relative;
  margin: pxToRem(40) 0 pxToRem(20) 0;
  padding-left: pxToRem(51);
  color: $pumpkin;
  font-size: pxToRem(18);
  font-family: $secondary__font-family;
  line-height: 1.67;

  &::before {
    content: "\201C";
    font-family: $secondary__font-family;
    font-size: pxToRem(18);
    font-weight: bold;
    left: 0;
    color: $orange--font;
    position: absolute;
  }

  &::after {
    content: "";
    width: pxToRem(2);
    height: 94%;
    background-color: $orange--font;
    position: absolute;
    left: pxToRem(29);
    top: pxToRem(7);
  }
}

figure {
  padding: pxToRem(17.5) 0;
  width: 100%;

  img {
    width: 100%;
    border-radius: pxToRem(10);
  }

  figcaption {
    font-size: pxToRem(11.25);
    line-height: 1.185;
    padding-top: pxToRem(13.5);
    color: $black-five;
  }
}

.pull-quote {
  border-top: 1px solid $base__font-colour;
  padding: 0.75em 0.375em 0 0.375em;
  margin: 0.75em;
}

/* Highlight */
.highlight {
  background: #cccccc;
  @extend %inline-block;
  padding: 0.1em 0.5em;
}
/* stylelint-enable */

@media screen and (min-width: 80rem) {
  h1 {
    font-size: pxToRem(30);
  }
  h2 {
    font-size: pxToRem(22.5);
  }
  h3 {
    font-size: pxToRem(18.75);
  }
  h4 {
    font-size: pxToRem(15);
    line-height: pxToRem(23);
  }
  h5 {
    font-size: pxToRem(13.5);
  }
  h6 {
    font-size: pxToRem(13.5);
  }

  p {
    font-size: pxToRem(13.5);
    line-height: pxToRem(20);
  }
}
