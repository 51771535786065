/* Module: S */
.testimonial {

    width: 100%;
    margin: pxToRem(14) 0 pxToRem(5) 0;
    @include sq-flex-box;
    flex-flow: row wrap;
    align-content: center;
    align-items: center;

    &__img {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        @include sq-border-radius(999px);
        width: pxToRem(125);
        height: pxToRem(125);
        margin: 0 0 pxToRem(15) 0;
    }

    &__content {
        font-size: pxToRem(16);
        line-height: pxToRem(26);
        width: 100%;
    }
}